<template>
  <div>

    <!-- Filter Section -->
    <FilterReport title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <!-- <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button> -->

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <h2>{{ companyName }}</h2>
          <h3>{{ $t('apps.reportPayment.singular.reportPayment') }}</h3>
          <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
          <h4 class="mt-sm-1">{{ periodReportText }}</h4>
        </b-card-body>

        <b-table-simple id="trial-balance-table"  sticky-header="500px" responsive>
          <b-thead>
            <b-tr>
              <b-th class="text-center" style="min-width: 50px;">No</b-th>
              <b-th class="text-center">No. Invoice</b-th>
              <b-th class="text-center">Document Invoice</b-th>
              <b-th class="text-center" style="min-width: 125px;">Date</b-th>
              <b-th class="text-center" style="min-width: 175px;">Vendor</b-th>
              <b-th class="text-center">Amount</b-th>
              <b-th class="text-center">Via Cash</b-th>
              <b-th class="text-center">Via Bank</b-th>
              <b-th class="text-center">Outstanding</b-th>
              <b-th class="text-center">Reference</b-th>
              <b-th class="text-center">Document Payment</b-th>
              <b-th class="text-center">Payment Date</b-th>
              <b-th class="text-center">Bank</b-th>
              <b-th class="text-center">Payment</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-for="(prop, index) in itemLists" :index="index" :key="prop.invoice_no">
            <b-tr>
              <!-- <b-td v-for="column in tableColumns" :key="`${column.key}-${index}`" :class="column.tdClass">{{ prop[column.key] }}</b-td> -->
              <b-td :rowspan="prop.countData + 1">{{ index + 1 }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.invoiceNo }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">
                <b-link
                  :to="{ name: 'apps-transactions-purchase-invoice-listImage', params: { id: prop.data[0].invoice_id }}"
                  class="font-weight-bolder"
                  >
                <span class="font-weight-bolder d-block text-nowrap">
                 <feather-icon
                  icon="FileTextIcon"
                  size="21"
                />
                </span>
              </b-link>
              </b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].tanggal_invoice }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].nama_akun }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].nilai_invoice_view }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].uang_diterima_cash_view }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].uang_diterima_bank_view }}</b-td>
              <b-td class="text-right" :rowspan="prop.countData + 1">{{ prop.data[0].outstanding_view }}</b-td>
            </b-tr>
            <b-tr v-for="(data) in prop.data" :key="data.invoice_no">
              <b-td class="text-right">{{ data.nomor_referensi }}</b-td>
              <b-td class="text-right"> 
                <b-link
                  :to="{ name: 'apps-transactions-purchase-payment-listImage', params: { id: data.id_payment }}"
                  class="font-weight-bolder"
                  >
                <span class="font-weight-bolder d-block text-nowrap">
                 <feather-icon
                  icon="FileTextIcon"
                  size="21"
                />
                </span>
              </b-link>
              </b-td>
              <b-td class="text-right">{{ data.tanggal_pembayaran }}</b-td>
              <b-td class="text-right">{{ data.id_pembayaran }}</b-td>
              <b-td class="text-right">{{ data.pelunasan_uang_view }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td class="text-center font-weight-bolder font-italic" colspan="5"></b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.nilaiInvoice }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.uangDiterimaCash }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.uangDiterimaBank }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.outstanding }}</b-td>
              <b-td class="text-center font-weight-bolder font-italic" colspan="3"></b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.pelunasanUang }}</b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot,
  BLink
} from 'bootstrap-vue'
import FilterReport from '@/components/reports/FilterReportPay.vue'
import { computed } from '@vue/composition-api'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'

export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    // BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    FilterReport,
    BLink
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'coa', label: 'COA', thClass:'', tdClass: '' },
      { key: 'beginning_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'beginning_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'mutation_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'mutation_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'balance_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      { key: 'balance_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' }
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
    
    const total = computed(() => {
      console.log(itemLists)
      const nilaiInvoice = formatCurrency(itemLists.value.reduce((accumulator, {data}) => accumulator + data[0].nilai_invoice, 0))
      const uangDiterimaCash = formatCurrency(itemLists.value.reduce((accumulator, {data}) => accumulator + data[0].uang_diterima_cash, 0))
      const uangDiterimaBank = formatCurrency(itemLists.value.reduce((accumulator, {data}) => accumulator + data[0].uang_diterima_bank, 0))
      const pelunasanUang = formatCurrency(itemLists.value.reduce((accumulator, {data}) => accumulator + data[0].pelunasan_uang, 0))
      const outstanding = formatCurrency(itemLists.value.reduce((accumulator, {data}) => accumulator + data[0].outstanding, 0))
      
      return { 
        nilaiInvoice,
        uangDiterimaCash,
        uangDiterimaBank,
        pelunasanUang,
        outstanding
      }
    })

    return {
      total,
      formatCurrency,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      ...useFilterReport({
        baseUrl: 'reports/payment',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Report Payment ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
